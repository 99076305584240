.section {
  background: #fff;
  box-shadow: 0px 8px 16px rgba(182, 162, 141, 0.5);
  border-radius: 16px;
  padding: 24px 48px;
  margin-bottom: 32px;

  @media only screen and (max-width: 768px) {
    padding: 16px 12px;
  }
}
