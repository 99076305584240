.Table__Row {
  background-color: #fff;
  position: relative;
  text-align: center;
  &:last-of-type {
    :global(.Table__Data) {
      border: unset;
    }
  }
}

.Table {
  width: 100%;
  height: 100%;
}

.Table--separate-row {
  border-collapse: separate;
  border-spacing: 0 4px;
  :global(.Table__Data) {
    border: none;
  }
}

.Table__Head--no-bg {
  :global(.Table__HeadData) {
    background-color: unset;
  }
}
.Table__Head--sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.Table--sticky-last-column {
  .Table__Row {
    :global(.Table__Data),
    :global(.Table__HeadData) {
      &:last-of-type {
        position: sticky;
        right: 0;
        background-color: #fff;
      }
    }
    :global(.Table__Data) {
      &:last-of-type {
        background-color: inherit;
      }
    }
    :global(.Table__HeadData) {
      &:last-of-type {
        background: rgba(183, 163, 142);
      }
    }
  }
}
