.sliderBar {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: theme('colors.wombatYellow2');
  outline: none;
  margin-top: 12px;
}

.sliderBar:hover {
  opacity: 1;
}

.sliderBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  appearance: none;
  width: 25px;
  height: 25px;
  background: url('../../public/slider.png');
  cursor: pointer;
  background-size: cover;
  box-shadow: none;
  border: none;
}

.sliderBar::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  width: 25px;
  height: 25px;
  background: url('../../public/slider.png');
  cursor: pointer;
  background-size: cover;
  box-shadow: none;
  border: none;
}
