$selected: #b672d6;
.popper {
  :global(.react-datepicker__header) {
    background-color: #fff;
  }
  :global(.react-datepicker__day--in-selecting-range),
  :global(.react-datepicker__day--in-range) {
    background-color: #b672d690 !important;
    color: #fff !important;
  }
  :global(.react-datepicker__day--selected),
  :global(.react-datepicker__day--range-start),
  :global(.react-datepicker__day--selecting-range-end) {
    background-color: $selected !important;
    color: #fff !important;
  }

  :global(.react-datepicker__time-list-item):hover {
    &:not(:global(.react-datepicker__time-list-item--selected), :global(.react-datepicker__time-list-item--disabled)) {
      background-color: #eae0ee !important;
    }
  }
  :global(.react-datepicker__time-list-item--selected) {
    background-color: $selected !important;
  }
}
