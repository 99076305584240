$scrollbarSize: 4px;
$borderSize: 7px;
.ScrollableBox {
  position: relative;
  &::-webkit-scrollbar {
    height: calc($scrollbarSize + $borderSize * 2);
    width: calc($scrollbarSize + $borderSize * 2);
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 999px;
    // add spacing between content and scrollbar
    border: $borderSize solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background-color: #b672d660;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b672d6;
  }
  &::-webkit-scrollbar-corner {
    width: 0;
  }
}
