.rd {
  display: flex;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.rd input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid #695b51;
}

/* On mouse-over, add a grey background color */
.rd:hover input[type='radio'] ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.rd input[type='radio']:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #695b51;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.rd input[type='radio']:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.rd .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  background: #695b51;
  border-radius: 2px;
}
