.tab {
  box-shadow: 4px 0 #fff, -4px 0 #fff, 0 -4px #fff, 0 4px #fff, 8px 0 #b672d6, -8px 0 #b672d6,
    0 -8px #b672d6, 0 8px #b672d6, 0 0 0 4px #b672d6;
  background-color: #fff;
  left: 50%;
  transform: translate(-50%, 0);
  top: -10px;
  z-index: 1;
}
.tab_title {
  box-shadow: 4px 0 #ebc226, -4px 0 #ebc226, 0 -4px #ebc226, 0 4px #ebc226, 8px 0 #fff, -8px 0 #fff,
    0 -8px #fff, 0 8px #fff, 0 0 0 4px #fff;
}

.fluidBorderAnimation {
  background: linear-gradient(45deg, #b672d6 30%, #ffdd52 50%, #b672d6 30%) 0% 0% / 300% 300%;
  /* background-position: 133% 50%; */
  animation: 4s linear 0s infinite normal none running ilqnTz;
  filter: blur(3px);
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
}

@keyframes ilqnTz {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
