/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */
@tailwind components;

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */
@tailwind utilities;

/**
  * Use this directive to control where Tailwind injects the hover, focus,
  * responsive, dark mode, and other variants of each class.
  *
  * If omitted, Tailwind will append these classes to the very end of
  * your stylesheet by default.
  */
@tailwind variants;

@layer components {
  .tooltip-brown {
    /* opacity is needed for the transition and visiblity is needed to be hidden  */
    @apply invisible absolute z-10 cursor-default bg-wombatBrown1/80 opacity-0 backdrop-blur-md transition-opacity duration-300 ease-in-out group-hover:visible group-hover:opacity-100;
  }

  .hover-text {
    @apply border-b-2 border-dotted border-wombatBrown1;
  }

  .button-hover-opacity {
    @apply transition-opacity hover:opacity-60;
  }

  .button-hover-opacity-light {
    @apply transition-opacity hover:opacity-80;
  }

  .text-gradient {
    background: linear-gradient(200deg, #ffdd52 -20%, #b672d6 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
