.tncPage {
  p {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 14px;
  }

  ul {
    list-style-type: disc;

    li {
      margin-left: 30px;
      font-size: 14px;
    }
  }

  h2 {
    font-weight: 600;
  }

  span {
    font-size: 14px;
  }
}
