@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&family=Quantico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quantico:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Quantico:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

#transparentBackground {
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.25);
}

body,
html {
  width: 100vw;
  overflow-x: hidden;
}

/* Chrome, Safari, Edge, Opera */
body {
  -webkit-tap-highlight-color: transparent;
}

.disable-scrolling {
  overflow-y: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
img {
  -webkit-user-drag: none;
}

/* Firefox */
input[type='number'] {
  --moz-appearance: textfield;
}

input[type='radio'] {
  padding: 0.5em;
  --webkit-appearance: none;
}

input[type='radio']:checked {
  background: url('../public/assets/background/radio-rectangle.svg') no-repeat center center;
}

input[type='radio']:focus {
  outline-color: transparent;
}

button {
  user-select: none;
}

.table__token-wrapper {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  gap: 8px;
  :first-child {
    justify-self: end;
  }
  :last-child {
    justify-self: start;
    text-align: left;
  }
}

.table_token-wrapper--flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.border-bottom--dotted {
  border-bottom: 1px dotted #695b51;
  padding-bottom: 2px;
}

.container--drop-shadow {
  background: #fff;
  box-shadow: 0px 8px 16px rgba(182, 162, 141, 0.5);
  border-radius: 16px;
}
