.AppTooltip {
  opacity: 1 !important;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
    cursor: text !important;
    user-select: text !important;
  }
}
